
export default {
  props: {
    id: {
      type: String,
      default: "rhrs6l0z",
    },
    deferScript: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      page_url: null,
      utm_source: null,
      utm_medium: null,
      utm_campaign: null,
      utm_content: null,
      sc: null,
      utm_term: null,
      id_form1: null,
      email: null,
      telephone: null,
      name: null,
    };
  },

  computed: {
    prefill() {
      return this.page_url;
    },
  },

  mounted() {
    this.page_url = window.location.href;
    this.utm_source = this.$route.query.utm_source;
    this.utm_medium = this.$route.query.utm_medium;
    this.utm_campaign = this.$route.query.utm_campaign;
    this.utm_content = this.$route.query.utm_content;
    this.sc = this.$route.query.sc;
    this.utm_term = this.$route.query.utm_term;
    this.id_form1 =
      this.$route.query.submissionid || this.$route.query.invitee_uuid;
    this.email = this.$route.query.email || this.$route.query.invitee_email;
    this.telephone = this.$route.query.telephone || this.$route.query.answer_1;
    this.name =
      this.$route.query.nom || this.$route.query.invitee_last_name || "";

    if (this.deferScript) {
      const events = ["pointermove", "scroll", "click", "touchstart"];
      const triggerPf = (e) => {
        this.appendScript();
        e.currentTarget.removeEventListener(e.type, triggerPf);
      };
      events.forEach((e) => {
        window.addEventListener(e, triggerPf);
      });
    } else {
      this.appendScript();
    }
  },

  methods: {
    appendScript() {
      const script = document.createElement("script");
      script.src = "https://paperform.co/__embed.min.js";
      document.body.appendChild(script);
    },
  },
};
