
import Articles from "~/assets/blog.json";

export default {
  data() {
    return {
      articles: Articles,
      path: this.$route.path,
    };
  },

  computed: {
    article() {
      return this.articles.find((x) =>
        "/blog/" + x.url === this.path ? x : null
      );
    },

    imgHero() {
      return require("~/assets/img/blog/" + this.article.image);
    },
  },

  mounted() {
    this.generateIds();
  },

  methods: {
    generateIds() {
      const hseconds = document.querySelectorAll(".content h2");
      hseconds.forEach((h2, i) => {
        h2.id = this.article.summary[i].slug;
      });
    },

    scrollSmooth() {
      document.documentElement.style.scrollBehavior = "smooth";
      setTimeout(() => {
        document.documentElement.style.scrollBehavior = "unset";
      }, 1000);
    },
  },
};
