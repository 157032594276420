export const state = () => ({
  isBannerActive: false,
});

export const getters = {
  getCounter(state) {
    return state.isBannerActive;
  },
};

export const mutations = {
  toggle(state) {
    state.isBannerActive = false;
  },
};
