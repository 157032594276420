
export default {
  props: {
    src: { type: String, default: "" },
    media: { type: String, default: "" },
    sizes: { type: String, default: "" },
    width: { type: String, default: "" },
    height: { type: String, default: "" },
    type: { type: String, default: "webp" },
  },
  computed: {
    cSizesWebp() {
      return this.$img.getSizes(this.src, {
        sizes: this.sizes,
        srcset: this.src,
        modifiers: {
          format: this.type,
        },
      });
    },
  },
};
