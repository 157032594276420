
export default {
  props: {
    isPopup: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      isPopupActive: false,
      isCookieActive: false,
    };
  },

  computed: {
    isNewsletterActive() {
      return this.$store.state.newsletter.isNewsletterActive;
    },
  },

  mounted() {
    this.getCookie();
    if (!this.isCookieActive && this.isPopup) {
      const eventScroll = (e) => {
        if (window.scrollY > 900) {
          this.isPopupActive = true;
          this.initTypeform();
          e.currentTarget.removeEventListener("scroll", eventScroll);
        }
      };
      window.addEventListener("scroll", eventScroll);
    }
  },

  methods: {
    closeNewsletter() {
      this.$store.commit("newsletter/toggle");
    },

    initTypeform() {
      const tf = () => import("@typeform/embed");

      tf().then(({ createWidget }) => {
        import("@typeform/embed/build/css/widget.css");

        createWidget("YkqIthYt", {
          container: document.querySelector("#form"),
          hideHeaders: true,
          hideFooter: true,
          opacity: 0,
          autoResize: "400, 500",
          lazy: true,
          inlineOnMobile: true,
          onSubmit: () => {
            this.setCookie();
            setTimeout(() => {
              this.closeNewsletter();
            }, 5000);
          },
        });
      });
    },

    setCookie() {
      const date = new Date();
      const name = "newsletterAleo=active;";
      date.setTime(date.getTime() + 1000 * 60 * 60 * 24 * 365);
      const expires = "expires=" + date.toUTCString() + ";";
      document.cookie = name + expires + ";path=/";
    },

    getCookie() {
      const cookie = document.cookie;
      if (cookie.includes("newsletterAleo=active")) {
        this.isCookieActive = true;
      }
    },
  },
};
