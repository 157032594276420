import { render, staticRenderFns } from "./Discover.vue?vue&type=template&id=6d3b46e0&scoped=true&"
var script = {}
import style0 from "./Discover.vue?vue&type=style&index=0&id=6d3b46e0&prod&lang=postcss&scoped=true&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6d3b46e0",
  null
  
)

export default component.exports