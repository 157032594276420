export const state = () => ({
  isPopupActive: true,
});

export const getters = {
  getCounter(state) {
    return state.isPopupActive;
  },
};

export const mutations = {
  toggle(state) {
    state.isPopupActive = false;
  },
};
