
export default {
  props: {
    color: {
      type: String,
      default: "white",
    },
    width: {
      type: String,
      default: "100",
    },
  },
};
