
export default {
  data() {
    return {
      isVisible: false,
    };
  },
  mounted() {
    const k = [38, 38, 40, 40, 37, 39, 37, 39, 66, 65];
    let n = 0;
    const that = this;

    document.addEventListener("keydown", function (e) {
      if (!this.isVisible) {
        if (e.keyCode === k[n++]) {
          if (n === k.length) {
            that.toggleVisible();
            n = 0;
            return false;
          }
        } else {
          n = 0;
        }
      }
    });
  },

  methods: {
    toggleVisible() {
      this.isVisible = !this.isVisible;

      if (this.isVisible) {
        document.body.style.height = "100vh";
        document.body.style.overflow = "hidden";
      } else {
        document.body.style.height = "unset";
        document.body.style.overflow = "unset";
      }
    },
  },
};
