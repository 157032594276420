import { render, staticRenderFns } from "./FooterV2.vue?vue&type=template&id=731ab4c3&"
import script from "./FooterV2.vue?vue&type=script&lang=js&"
export * from "./FooterV2.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Logo: require('/app/components/Logo.vue').default,NewsletterForm: require('/app/components/NewsletterForm.vue').default,Footer: require('/app/components/Footer.vue').default})
