
export default {
  props: {
    isLp: {
      type: Boolean,
      default: false,
    },
    textButton: {
      type: String,
      default: "Prendre rendez-vous",
    },
  },
  data() {
    return {
      isCalendlyActive: false,
    };
  },

  methods: {
    activeCalendly() {
      this.isCalendlyActive = false;
      this.$nextTick(() => {
        this.isCalendlyActive = true;
      });
    },
  },
};
