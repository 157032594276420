export default ({ app }) => {
  app.router.afterEach((to, from) => {
    if (from.query && to.query) {
      const preservedQuery = {};

      Object.keys(from.query).forEach((key) => {
        if (!(key in to.query)) {
          preservedQuery[key] = from.query[key];
        }
      });

      if (Object.keys(preservedQuery).length > 0) {
        app.router.replace({
          query: { ...to.query, ...preservedQuery },
        });
      }
    }
  });
};
