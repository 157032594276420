
export default {
  props: {
    text: {
      type: String,
      default: "Ajouter un texte",
    },
    textSize: {
      type: String,
      default: "",
    },
    iconName: {
      type: String,
      default: "icon-phone",
    },
    iconPosition: {
      type: String,
      default: "",
    },
    color: {
      type: String,
      default: "text-primary-500",
    },
    background: {
      type: String,
      default: "bg-white",
    },
    fill: {
      type: String,
      default: "fill-primary-500",
    },

    borderColor: {
      type: String,
      default: "",
    },
    link: {
      type: String,
      default: "",
    },
    url: {
      type: String,
      default: "",
    },
    isBlank: {
      type: Boolean,
      default: false,
    },

    isTypeform: {
      type: Boolean,
      default: false,
    },
    idTypeform: {
      type: String,
      default: "yecdykrx",
    },

    hash: {
      type: String,
      default: "",
    },
  },

  data() {
    return {
      isTypeformActive: false,
    };
  },

  methods: {
    activeTypeform() {
      if (this.isTypeform) {
        this.isTypeformActive = false;
        this.$nextTick(() => {
          this.isTypeformActive = true;
        });
      }
    },
  },
};
