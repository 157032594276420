
import Testimonials from "~/assets/testimonials.json";
import Categories from "~/assets/categories.json";

export default {
  data() {
    return {
      testimonials: Testimonials,
      categories: Categories,
      path: this.$route.name,
      isCalendlyActive: false,
    };
  },
  computed: {
    testimonial() {
      let testi = "";
      this.testimonials.forEach((x) =>
        x.url === this.path ? (testi = x) : null
      );
      return testi;
    },
  },
};
