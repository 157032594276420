
export default {
  props: {
    isSecondFooter: {
      type: Boolean,
      default: false,
    },

    isFooterLp: {
      type: Boolean,
      default: false,
    },

    tel: {
      type: String,
      default: "0 806 701 801",
    },

    hrefTel: {
      type: String,
      default: "tel:0-806-701-801",
    },

    isTypeform: {
      type: Boolean,
      default: true,
    },

    idTypeform: {
      type: String,
      default: "nVjz8LEL",
    },

    url: {
      type: String,
      default: "",
    },
  },

  data() {
    return {
      isOver: false,
      tintin: "",
      isTintin: false,
      isCalendlyActive: false,
    };
  },

  methods: {
    activeContact() {
      this.$root.$emit("Header");
    },

    tintinStart() {
      this.tintin = setTimeout(() => {
        this.isTintin = true;
      }, 30000);
    },

    tintinStop() {
      clearTimeout(this.tintin);
    },
  },
};
