
export default {
  props: {
    colorLogo: {
      type: String,
      default: "#E73331",
    },
    itemsWhite: {
      type: Boolean,
      default: false,
    },
    colorButton: {
      type: String,
      default: "text-white",
    },
    colorBackgroundButton: {
      type: String,
      default: "bg-primary-500",
    },
    contactColor: {
      type: String,
      default: "text-primary-500",
    },
    contactFill: {
      type: String,
      default: "fill-primary-500",
    },
    burgerColor: {
      type: String,
      default: "#FF4235",
    },
  },
  data() {
    return {
      isMenuActive: false,
      isSticky: false,
      oldScroll: 0,
      sticky: false,
      isContact: false,
      isPaperformActive: false,
      socialMedias: [
        {
          link: "https://www.facebook.com/aleo.agency/",
          logo: require("~/assets/img/facebook-red.svg"),
          alt: "logo facebook",
          ariaLabel: "link facebook",
        },
        {
          link: "https://www.instagram.com/aleo.agency/?hl=fr",
          logo: require("~/assets/img/instagram-red.svg"),
          alt: "logo instagram",
          ariaLabel: "link instagram",
        },
        {
          link: "https://www.linkedin.com/company/aleo-agency/",
          logo: require("~/assets/img/linkedin-red.svg"),
          alt: "logo linkedin",
          ariaLabel: "link linkedin",
        },
        {
          link: "https://www.youtube.com/channel/UC4GBqxVpyYLiZGHRiA2RbPQ",
          logo: require("~/assets/img/youtube-red.svg"),
          alt: "logo youtube",
          ariaLabel: "link youtube",
        },
        {
          link: "https://www.tiktok.com/@aleo.agency",
          logo: require("~/assets/img/tiktok-red.svg"),
          alt: "logo tiktok",
          ariaLabel: "link tiktok",
        },
      ],
    };
  },

  computed: {
    isSmallThanLg() {
      return this.$breakpoints.sLg;
    },
  },

  mounted() {
    window.addEventListener("scroll", this.onScroll);

    this.$root.$on("Header", () => {
      this.activeContact();
    });

    document.body.style.height = "unset";
    document.body.style.overflow = "unset";
  },
  methods: {
    updateStateMenu(variable) {
      this.isMenuActive = variable;
    },
    onScroll(e) {
      if (this.oldScroll < window.scrollY && window.scrollY > 100) {
        this.isSticky = true;
      } else if (this.oldScroll > window.scrollY) {
        this.isSticky = false;
      }

      if (window.scrollY > 0) {
        this.sticky = true;
      } else {
        this.sticky = false;
      }

      this.oldScroll = window.scrollY;
    },

    activeContact() {
      window.scrollBy(0, -1);
      this.isContact = !this.isContact;
      if (this.isContact) {
        document.body.style.height = "100vh";
        document.body.style.overflow = "hidden";
      } else {
        document.body.style.height = "unset";
        document.body.style.overflow = "unset";
      }
    },

    activeMenu() {
      this.isMenuActive = !this.isMenuActive;
      if (this.isMenuActive) {
        document.body.style.height = "100vh";
        document.body.style.overflow = "hidden";
      } else {
        document.body.style.height = "unset";
        document.body.style.overflow = "unset";
      }
    },

    // activeTypeform() {
    //   this.isPaperformActive = false;
    //   this.$nextTick(() => {
    //     this.isPaperformActive = true;
    //   });
    // },
  },
};
