
export default {
  props: {
    idPaperform: {
      type: String,
      default: "punbftfw",
    },
    idBtn: {
      type: String,
      default: null,
    },
  },

  data() {
    return {
      page_url: null,
    };
  },

  computed: {
    prefill() {
      return this.page_url;
    },
  },

  mounted() {
    this.page_url = window.location.href;
    const script = document.createElement("script");
    script.src = "https://paperform.co/__embed.min.js";
    script.onload = () => {
      // eslint-disable-next-line no-undef
      Paperform.popup(this.idPaperform, {
        prefill: {
          page_url: this.prefill,
          utm_source: this.$route.query.utm_source,
          utm_medium: this.$route.query.utm_medium,
          utm_campaign: this.$route.query.utm_campaign,
          utm_content: this.$route.query.utm_content,
          sc: this.$route.query.sc,
          offre: this.idBtn,
        },
      });
    };
    document.body.appendChild(script);
  },
};
